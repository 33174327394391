import clsx from "clsx";
import React, { useRef, useState, useEffect } from "react";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";

import SvgIcon from "../SvgIcon";

import styles from "./style.module.scss";

interface HorizontalScrollProps {
  children: React.ReactNode;
  scrollAmount?: number;
}

const HorizontalScroll: React.FC<HorizontalScrollProps> = ({
  children,
  scrollAmount = 200
}) => {
  const scrollableNodeRef = useRef<HTMLElement | null>(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  const updateButtonVisibility = () => {
    if (scrollableNodeRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollableNodeRef.current;
      setShowLeftButton(scrollLeft > 0);
      setShowRightButton(scrollLeft < scrollWidth - clientWidth - 1); // -1 to account for potential rounding errors
    }
  };

  useEffect(() => {
    const scrollableNode = scrollableNodeRef.current;
    if (scrollableNode) {
      scrollableNode.addEventListener("scroll", updateButtonVisibility);
      // Initial check
      updateButtonVisibility();
    }
    return () => {
      scrollableNode?.removeEventListener("scroll", updateButtonVisibility);
    };
  }, []);

  const scroll = (direction: "left" | "right") => {
    if (scrollableNodeRef.current) {
      const currentScroll = scrollableNodeRef.current.scrollLeft;
      scrollableNodeRef.current.scrollTo({
        left:
          currentScroll + (direction === "left" ? -scrollAmount : scrollAmount),
        behavior: "smooth"
      });
    }
  };

  return (
    <div className="position-relative d-flex">
      {showLeftButton && (
        <button
          className={clsx("btn", styles.scrollButton, styles.leftScrollButton)}
          onClick={() => scroll("left")}
          style={{ zIndex: 10 }}
        >
          <SvgIcon name="chevron-left" />
        </button>
      )}

      <SimpleBar
        scrollableNodeProps={{ ref: scrollableNodeRef }}
        style={{ maxWidth: "100%", overflow: "hidden" }}
        autoHide={true}
      >
        <div className="d-flex">{children}</div>
      </SimpleBar>

      {showRightButton && (
        <button
          className={clsx("btn", styles.scrollButton, styles.rightScrollButton)}
          onClick={() => scroll("right")}
          style={{ zIndex: 10 }}
        >
          <SvgIcon name="chevron-right" />
        </button>
      )}
    </div>
  );
};

export default HorizontalScroll;
