import Scrollbar from "components/General/Scrollbar";

import ContextHeader from "../../Reusables/ContextHeader";
import ScriptEditor from "components/ScriptEditor";
import { VideoScene } from "support/types/videos";

import styles from "./style.module.scss";
import "./styles.scss";
import { useVideoEditor } from "contexts/VideoEditorContext";
import clsx from "clsx";
import { MouseEvent } from "react";

export default function ScriptContext() {
  const {
    video,
    isVideoLoading,
    isScenesLoading,
    scenes,
    updateScene,
    currentScene,
    setCurrentSceneIndex,
    setCurrentWord
  } = useVideoEditor();

  const handleOnSelectScene = (e: MouseEvent, i: number) => {
    e.stopPropagation();

    setCurrentSceneIndex(i);
  };

  const handleCurrentWordChange = (scene: VideoScene, word: string) => {
    setCurrentWord(word);
  };

  const handleScriptChange = (scene: VideoScene, text: string) => {
    updateScene({
      ...scene,
      script: text
    });
  };

  return (
    <div className={styles.navContentContext}>
      <ContextHeader>
        <h4 className={styles.heading}>Video Script</h4>
        <p className={styles.paragraph}>
          Your script will be narrated by the AI voiceover exactly as written.
          It also serves as the transcript for subtitles. Always verify AI
          generated infomation for accuracy.
        </p>
      </ContextHeader>

      <Scrollbar className={styles.scriptContextBody}>
        <div className={styles.scriptsWrap}>
          {(isVideoLoading || isScenesLoading || !video?.scenesReady) &&
            Array.from({ length: 10 }, (_, index) => (
              <div key={index + 1} className={styles.scriptLoadingItem}></div>
            ))}

          {!!scenes?.length &&
            scenes.map((scene, i) => (
              <div
                key={scene.id}
                className={clsx(styles.scriptItem, {
                  [styles.activeScript]: scene.id === currentScene?.id
                })}
                onClick={e => handleOnSelectScene(e, i)}
              >
                <div className={styles.editorWrap}>
                  <ScriptEditor
                    content={scene.script}
                    className="sidenav-scripts"
                    onCurrentWordChange={word =>
                      handleCurrentWordChange(scene, word)
                    }
                    onChange={text => handleScriptChange(scene, text)}
                  />
                </div>
              </div>
            ))}
        </div>
      </Scrollbar>
    </div>
  );
}
