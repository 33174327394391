import React, { useEffect, useState } from "react";
import "./styles.scss";

interface Subtitle {
  word: string;
}

interface StyledSubtitle extends Subtitle {
  outerStyles: React.CSSProperties;
  innerStyles: React.CSSProperties;
  duration: number; // Duration in seconds
}

interface WordForWordSubtitleProps {
  subtitles: Subtitle[];
  paused?: boolean; // Optional paused prop
}

const WordForWordSubtitle: React.FC<WordForWordSubtitleProps> = ({
  subtitles,
  paused = false
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [styledSubtitles, setStyledSubtitles] = useState<StyledSubtitle[]>([]);

  useEffect(() => {
    // Calculate min and max word lengths
    const wordLengths = subtitles.map(sub => sub.word.length);
    const minWordLength = Math.min(...wordLengths);
    const maxWordLength = Math.max(...wordLengths);

    // Precompute styles for each subtitle
    const computedSubtitles: StyledSubtitle[] = subtitles.map(sub => {
      const outerStyles: React.CSSProperties = {};
      const innerStyles: React.CSSProperties = {};

      // Random rotation with 20% chance
      if (Math.random() > 0.8) {
        const rotation = Math.floor(Math.random() * 35);
        outerStyles.transform = `rotate(${rotation}deg)`;
      }

      // Random color change with 2% chance
      if (Math.random() > 0.98) {
        const colors = ["#0000FF", "#00FFFF"]; // Blue and Cyan
        const randomColor = colors[Math.floor(Math.random() * colors.length)];
        innerStyles.color = randomColor;
      } else {
        innerStyles.color = "white";
      }

      // Random font selection
      const font = Math.random() > 0.1 ? "Montserrat" : "Roboto";
      innerStyles.fontFamily = font;

      // Bounce animation settings based on word length
      const minDuration = 0.2; // Minimum duration in seconds
      const maxDuration = 0.5; // Maximum duration in seconds
      const wordLength = sub.word.length;

      let duration = minDuration;

      if (maxWordLength !== minWordLength) {
        duration =
          minDuration +
          ((wordLength - minWordLength) / (maxWordLength - minWordLength)) *
            (maxDuration - minDuration);
      }

      innerStyles.animationName = "bounceEffect";
      innerStyles.animationDuration = `${duration}s`;
      innerStyles.animationTimingFunction = "ease-out";
      innerStyles.animationFillMode = "forwards";

      return {
        ...sub,
        outerStyles,
        innerStyles,
        duration // Store duration for timing
      };
    });

    setStyledSubtitles(computedSubtitles);
  }, [subtitles]);

  useEffect(() => {
    if (paused) {
      return setCurrentIndex(0);
    }

    if (styledSubtitles.length === 0) return;

    let timeoutId: NodeJS.Timeout;

    const currentSubtitle = styledSubtitles[currentIndex];
    const durationInMs = currentSubtitle.duration * 1000;

    // Schedule the next word change
    timeoutId = setTimeout(() => {
      setCurrentIndex(prevIndex => (prevIndex + 1) % styledSubtitles.length);
    }, durationInMs);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [currentIndex, styledSubtitles, paused]);

  return (
    <div className="subtitle-animator">
      {styledSubtitles.length > 0 && (
        <div
          className="subtitle-container"
          style={styledSubtitles[currentIndex].outerStyles}
        >
          <div
            className="subtitle-text"
            style={{
              ...styledSubtitles[currentIndex].innerStyles,
              animationPlayState: paused ? "paused" : "running"
            }}
          >
            {styledSubtitles[currentIndex].word.toUpperCase()}
          </div>
        </div>
      )}
    </div>
  );
};

export default WordForWordSubtitle;
