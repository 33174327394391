import "./styles.scss";

import React, { useEffect, useState, useMemo } from "react";
import { motion } from "framer-motion";

interface Subtitle {
  word: string;
}

interface WordWithEffect {
  word: string;
  lineIndex: number;
  wordIndex: number;
}

interface SubtitleSnapshot {
  time: number;
  lines: string[][];
  wordWithEffect: WordWithEffect | null;
}

interface KeepUpSubtitleProps {
  subtitles: Subtitle[];
  isPlaying: boolean;
}

const getDurationForWord = (word: string): number => {
  const minDuration = 0.2;
  const maxDuration = 0.5;
  const minLength = 1;
  const maxLength = 10; // Words longer than 10 characters get max duration

  const length = word.length;
  const duration =
    minDuration +
    ((Math.min(length, maxLength) - minLength) / (maxLength - minLength)) *
      (maxDuration - minDuration);
  return duration;
};

const processSubtitlesIntoSnapshots = (
  subtitles: Subtitle[]
): SubtitleSnapshot[] => {
  const snapshots: SubtitleSnapshot[] = [];

  let currentLines: string[][] = [[], [], []];
  let currentLineIndex = 0;
  let wordCountInLine = 0;
  let currentTime = 0;

  for (let i = 0; i < subtitles.length; i++) {
    const sub = subtitles[i];
    const duration = getDurationForWord(sub.word);

    // Add word to current line
    currentLines[currentLineIndex].push(sub.word);
    const wordIndexInLine = currentLines[currentLineIndex].length - 1;

    // Set current word as word with effect
    const wordWithEffect = {
      word: sub.word,
      lineIndex: currentLineIndex,
      wordIndex: wordIndexInLine
    };

    // Create snapshot
    snapshots.push({
      time: currentTime,
      lines: currentLines.map(line => [...line]), // Clone the lines to avoid mutation
      wordWithEffect: wordWithEffect
    });

    // Update currentTime
    currentTime += duration;

    wordCountInLine += 1;
    if (wordCountInLine >= 3) {
      wordCountInLine = 0;
      currentLineIndex += 1;
      if (currentLineIndex >= 2) {
        // Reset lines after 3 lines
        currentLineIndex = 0;
        currentLines = [[], [], []];

        // Create snapshot to represent the line reset
        snapshots.push({
          time: currentTime,
          lines: currentLines.map(line => [...line]),
          wordWithEffect: null
        });
      }
    }
  }

  return snapshots;
};

interface WordProps {
  word: string;
  hasEffect: boolean;
}

const Word: React.FC<WordProps> = ({ word, hasEffect }) => {
  const variants = {
    initial: { scale: 1.2, color: "#00FF00" },
    animate: { scale: 1.0, color: "#FFFFFF", transition: { duration: 0.6 } }
  };

  return hasEffect ? (
    <motion.span
      initial="initial"
      animate="animate"
      variants={variants}
      style={{ marginRight: "4px" }}
    >
      {word}
    </motion.span>
  ) : (
    <span style={{ marginRight: "4px", color: "#FFFFFF" }}>{word}</span>
  );
};

const KeepUpSubtitle: React.FC<KeepUpSubtitleProps> = ({
  subtitles,
  isPlaying
}) => {
  const [snapshots, setSnapshots] = useState<SubtitleSnapshot[]>([]);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    const processedSnapshots = processSubtitlesIntoSnapshots(subtitles);
    setSnapshots(processedSnapshots);
  }, [subtitles]);

  // Calculate total duration
  const totalDuration = useMemo(() => {
    if (snapshots.length === 0) return 0;
    return snapshots[snapshots.length - 1].time;
  }, [snapshots]);

  // Update currentTime based on isPlaying
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (isPlaying && totalDuration > 0) {
      interval = setInterval(() => {
        setCurrentTime(prevTime => {
          let newTime = prevTime + 0.1; // Update every 100ms
          if (newTime > totalDuration) {
            newTime = 0; // Loop back to start
          }
          return newTime;
        });
      }, 100);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isPlaying, totalDuration]);

  // Find the latest snapshot where snapshot.time <= currentTime
  const currentSnapshot = useMemo(() => {
    if (snapshots.length === 0) return null;

    let snapshot = snapshots[0];
    for (let i = 1; i < snapshots.length; i++) {
      if (snapshots[i].time <= currentTime) {
        snapshot = snapshots[i];
      } else {
        break;
      }
    }
    return snapshot;
  }, [snapshots, currentTime]);

  if (!currentSnapshot) {
    return null; // or some placeholder
  }

  const { lines, wordWithEffect } = currentSnapshot;

  return (
    <div className="subtitles">
      {lines.map((lineWords, lineIndex) => (
        <div key={lineIndex} className="subtitle-line">
          {lineWords.map((word, wordIndex) => {
            const hasEffect =
              wordWithEffect &&
              wordWithEffect.lineIndex === lineIndex &&
              wordWithEffect.wordIndex === wordIndex;
            return (
              <Word
                key={`${lineIndex}-${wordIndex}-${word}`}
                word={word}
                hasEffect={!!hasEffect}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default KeepUpSubtitle;
