import { useState } from "react";

import Scrollbar from "components/General/Scrollbar";

// import ContextHeader from "../../Reusables/ContextHeader";
// import TabButtons, { TabButtonItem } from "../../Reusables/TabButtons";

import ImageAI from "./AI";
// import ImageUploads from "./Uploads";
// import UnsplashImages from "./Unsplash";
// import PixelsImages from "./Pixels";

import styles from "./style.module.scss";

// const tabs = [
//   { id: "ai", title: "AI Image" },
//   { id: "uploads", title: "Uploads" },
//   { id: "unsplash", title: "Unsplash", badge: "Stock Photos" },
//   { id: "pixels", title: "Pixels", badge: "Stock Photos" }
// ];

export default function ImagesContext() {
  const [activeTab, setActiveTab] = useState<string | number>("ai");

  let activeTabContent: React.ReactNode;

  switch (activeTab) {
    case "ai":
      activeTabContent = <ImageAI />;
      break;
    // case "uploads":
    //   activeTabContent = <ImageUploads />;
    //   break;
    // case "unsplash":
    //   activeTabContent = <UnsplashImages />;
    //   break;
    // case "pixels":
    //   activeTabContent = <PixelsImages />;
    //   break;
    default:
      activeTabContent = null;
      break;
  }

  return (
    <div className={styles.navTextContext}>
      {/* <ContextHeader>
        <TabButtons
          active={activeTab}
          items={tabs}
          onChange={(item: TabButtonItem) => setActiveTab(item.id)}
        />
      </ContextHeader> */}

      <Scrollbar className={styles.imagesContextBody}>
        {activeTabContent}
      </Scrollbar>
    </div>
  );
}
