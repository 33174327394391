import clsx from "clsx";

import Scrollbar from "components/General/Scrollbar";
import AudioSamplePlayer from "components/General/AudioSamplePlayer";

import ContextHeader from "../../Reusables/ContextHeader";
import ContextSearchInput from "../../Reusables/SearchInput";
import SectionTitle from "../../Reusables/SectionTitle";

import styles from "./style.module.scss";
import { useEffect } from "react";
import { useVideoEditor } from "contexts/VideoEditorContext";

const cdnHost = process.env.REACT_APP_API_URL;

const sounds = [
  {
    name: "inspiring-cinematic",
    src: "inspiring-cinematic.mp3"
  },
  {
    name: "cinematic-dramatic",
    src: "cinematic-dramatic.mp3"
  }
];

export default function SoundContext() {
  const { video, setVideo } = useVideoEditor();

  useEffect(() => {
    if (!video) {
      return;
    }

    if (!video.backgroundMusic) {
      setVideo({ ...video, backgroundMusic: sounds[0].name });
    }
  }, [video, setVideo]);

  const handleBackgroundMusicChange = (name: string) => {
    if (!video) {
      return;
    }

    setVideo({ ...video, backgroundMusic: name });
  };

  return (
    <div className={styles.navTextContext}>
      <ContextHeader>
        <ContextSearchInput placeholder="Search Sounds..." />
      </ContextHeader>

      <Scrollbar className={styles.soundContextBody}>
        <SectionTitle>Background Sounds</SectionTitle>
        <div className={styles.soundsWrap}>
          {sounds.map(sound => (
            <div
              key={sound.name}
              className={clsx(styles.soundItemWrap, {
                [styles.active]: video?.backgroundMusic === sound.name
              })}
              onClick={() => handleBackgroundMusicChange(sound.name)}
            >
              <div key={sound.name} className={styles.soundItem}>
                <AudioSamplePlayer
                  className="voice-sammple-player"
                  src={`${cdnHost}/assets/sounds/${sound.src}`}
                />
              </div>
            </div>
          ))}
        </div>
      </Scrollbar>
    </div>
  );
}
