import { ReactNode } from "react";
import styles from "./style.module.scss";

interface ContextHeaderProps {
  children?: ReactNode;
}

export default function ContextHeader(props: ContextHeaderProps) {
  return <div className={styles.naContextHeader}>{props.children}</div>;
}
