import clsx from "clsx";
import { ReactNode } from "react";

import styles from "./style.module.scss";

interface SectionTitleProps {
  children?: ReactNode;
  className?: string;
}

export default function SectionTitle(props: SectionTitleProps) {
  return (
    <div className={clsx(styles.sectionTitle, props.className)}>
      {props.children}
    </div>
  );
}
