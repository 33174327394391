import styles from "./style.module.scss";

interface VideoPlayerProps {
  src: string;
  poster?: string;
  autoPlay?: boolean;
}

const VideoPlayer = ({ poster, src, autoPlay }: VideoPlayerProps) => {
  return (
    <video
      controls
      preload="auto"
      width="100%"
      height="100%"
      poster={poster}
      autoPlay={autoPlay}
      className={styles.videoPlayer}
    >
      <source src={src} type="video/mp4" />
      <p className="no-video-tag">
        Your web browser does not support HTML5 video
      </p>
    </video>
  );
};

export default VideoPlayer;
