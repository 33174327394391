import clsx from "clsx";
import { useState } from "react";
import Scrollbar from "components/General/Scrollbar";

import ContextHeader from "../../Reusables/ContextHeader";
import HorizontalScroll from "components/General/HorizontalScroll";

import WordForWordSubtitle from "components/Editor/CaptionStyles/WordForWord";
import KeepUpSubtitle from "components/Editor/CaptionStyles/KeepUp";

import { useVideoEditor } from "contexts/VideoEditorContext";

import styles from "./style.module.scss";

const captionStyles = [
  {
    name: "WordForWord",
    title: "Word for word"
  },
  {
    name: "KeepUp",
    title: "Keep Up"
  }
];

const subtitles = [
  { word: "Hello" },
  { word: "Welcome" },
  { word: "To" },
  { word: "Soutle!" },
  { word: "Create" },
  { word: "Something" },
  { word: "Awesome" },
  { word: "Today" },
  { word: "I" },
  { word: "Hope" },
  { word: "You" },
  { word: "Are" },
  { word: "Having" },
  { word: "a" },
  { word: "Great" },
  { word: "time" }
];

export default function DesignContext() {
  const [overCaption, setOverCaption] = useState("");
  const { video, setVideo } = useVideoEditor();

  const handleCaptionStyleHover = (caption: string) => {
    setOverCaption(caption);
  };

  const handleCaptionChange = (name: string) => {
    if (!video) {
      return;
    }

    setVideo({ ...video, captionStyle: name });
  };

  return (
    <div className={styles.navContentContext}>
      <ContextHeader>
        <h4 className={styles.heading}>Style</h4>
      </ContextHeader>

      <Scrollbar className={styles.designContextBody}>
        <h5 className={styles.subHeadding}>Caption Styles</h5>

        {/* <SubtitleAnimator subtitles={subtitles} /> */}

        <HorizontalScroll>
          <div className={styles.captionStylesWrap}>
            {captionStyles.map(caption => (
              <div
                key={caption.name}
                className={clsx(styles.captionStyleItem, {
                  [styles.active]: caption.name === video?.captionStyle
                })}
                onMouseEnter={() => handleCaptionStyleHover(caption.name)}
                onMouseLeave={() => setOverCaption("")}
                onClick={() => handleCaptionChange(caption.name)}
              >
                <div className={styles.captionStyleBg}>
                  {caption.name === "WordForWord" && (
                    <WordForWordSubtitle
                      subtitles={subtitles}
                      paused={caption.name !== overCaption}
                    />
                  )}
                  {caption.name === "KeepUp" && (
                    <KeepUpSubtitle
                      subtitles={subtitles}
                      isPlaying={caption.name === overCaption}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </HorizontalScroll>
      </Scrollbar>
    </div>
  );
}
