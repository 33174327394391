import { useRef } from "react";
import { useParams } from "react-router-dom";

import styles from "./style.module.scss";
import EditorTopNav from "components/Editor/TopNav";
import EditorTimeline from "components/Editor/Timeline";
import ScenePreview from "components/Editor/ScenePreview";
import EditorSideNav from "components/Editor/SideNav";

export default function Editor() {
  const parentRef = useRef<HTMLDivElement>(null);
  let { id: videoId } = useParams();

  if (!videoId) {
    return null;
  }

  return (
    <div className={styles.videoEditorPage}>
      <EditorTopNav />

      <div className={styles.editMain}>
        <EditorSideNav />

        <div ref={parentRef} className={styles.editMainContent}>
          <ScenePreview />

          <div className={styles.editorFooter}>
            <EditorTimeline />
          </div>
        </div>
      </div>
    </div>
  );
}
