import clsx from "clsx";
import SvgIcon from "components/General/SvgIcon";

import { useBilling } from "contexts/BillingContext";
import { useAuth } from "contexts/AuthContext";
import { useEffect, useState } from "react";

import styles from "./style.module.scss";

type AvailableCreditsProps = {
  className?: string;
};

export const AvailableCredits = (props: AvailableCreditsProps) => {
  const { showTopUpModal } = useBilling();
  const { user } = useAuth();

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleCreditClick = () => {
    if (!isSmallScreen) {
      showTopUpModal(); // Only show modal on larger screens
    }
  };

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 576); // Update for small screens (576px and below)
    };

    checkScreenSize(); // Run initially to check the current screen size

    window.addEventListener("resize", checkScreenSize); // Update screen size on resize

    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const credits = Math.round((user?.credits || 0) * 100) / 100;

  return (
    <div className={styles.availableCreditsWrap}>
      {/* Conditionally render based on screen size */}
      {!isSmallScreen ? (
        <div
          className={clsx(styles.availableCredits)}
          onClick={handleCreditClick}
        >
          <SvgIcon name="trophy" className={styles.trophyIcon} />
          {credits}
          <span className={styles.creditText}> Credits</span>
          <button className={styles.creditTopup} onClick={showTopUpModal}>
            Top up
          </button>
        </div>
      ) : (
        // Render a new button for small screens
        <button className={styles.smallScreenButton} onClick={showTopUpModal}>
          <SvgIcon name="trophy" className={styles.trophyIcon} />
          {credits}
        </button>
      )}
    </div>
  );
};
