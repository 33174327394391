import { useParams } from "react-router-dom";

import { VideoEditorProvider } from "contexts/VideoEditorContext";
import Editor from "./Editor";

export default function VideoEditorRoot() {
  let { id: videoId } = useParams();

  if (!videoId) {
    return null;
  }

  return (
    <VideoEditorProvider videoId={videoId}>
      <Editor />
    </VideoEditorProvider>
  );
}
