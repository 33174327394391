import SvgIcon from "components/General/SvgIcon";

import ScriptContext from "./Script";
import DesignContext from "./Design";
import SoundContext from "./Sound";
import ImagesContext from "./Images";
import SettingsContext from "./Settings";

import styles from "./style.module.scss";
import clsx from "clsx";

interface SideNavContextsProps {
  active?: string | null;
  collapse?: boolean;
  onToggleCollapse?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function SideNavContexts({
  active,
  collapse = false,
  onToggleCollapse
}: SideNavContextsProps) {
  const onToggleCollapseClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onToggleCollapse && onToggleCollapse(e);
  };

  if (collapse) {
    return null;
  }

  let context: React.ReactNode | null;

  switch (active) {
    case "script":
      context = <ScriptContext />;
      break;

    case "style":
      context = <DesignContext />;
      break;

    case "sound":
      context = <SoundContext />;
      break;

    case "images":
      context = <ImagesContext />;
      break;

    case "settings":
      context = <SettingsContext />;
      break;

    default:
      context = null;
      break;
  }

  return (
    <>
      <div className={styles.sideNavContext}>{context}</div>

      <button
        className={clsx("btn", styles.sideNavCloseButton)}
        onClick={e => onToggleCollapseClick(e)}
      >
        <SvgIcon name="close" className={styles.sideNavCloseIcon} />
      </button>
    </>
  );
}
