import clsx from "clsx";
import { Link, NavLink } from "react-router-dom";

import styles from "./style.module.scss";
import SvgIcon from "components/General/SvgIcon";

type SideNavProps = {
  className?: string;
  setIsSideNavOpen: React.Dispatch<React.SetStateAction<boolean>>; // Add prop type for the toggle function
};

const navigations = [
  {
    label: "Dashboard",
    route: "/dashboard",
    icon: "signal",
    isEnd: true,
  },
  {
    label: "Videos",
    route: "/videos",
    icon: "video",
    isEnd: false,
  },
  {
    label: "Series",
    route: "/series",
    icon: "video-series",
    isEnd: true,
  },
  {
    label: "Channels",
    route: "/channels",
    icon: "channels",
    isEnd: true,
  },
  {
    label: "Settings",
    route: "/settings",
    icon: "settings",
    isEnd: false,
  },
];

export default function SideNav(props: SideNavProps) {
  return (
    <nav className={clsx(styles.sideNav, props.className)}>
      <Link to="/dashboard" className={styles.logo}>
        <div className="invisible">Soutle</div>
        <div className={styles.by}> by Aptiw</div>
      </Link>

      <ul className={styles.navList}>
        {navigations.map((nav) => (
          <li key={nav.route}>
            <NavLink
              to={nav.route}
              end={nav.isEnd}
              className={({ isActive }) =>
                clsx({ [styles.activeLink]: isActive })
              }
              onClick={() => props.setIsSideNavOpen(false)} // Close SideNav on item click
            >
              <SvgIcon name={nav.icon} className={styles.navIcon} />
              {nav.label}
            </NavLink>
          </li>
        ))}
      </ul>

      <div className={styles.footerSection}>
        &copy; {new Date().getFullYear()} Aptiw, Inc.
      </div>
    </nav>
  );
}
