import React, { useEffect, useState } from "react";

import styles from "./style.module.scss";
import clsx from "clsx";

interface ImageWithLoadingProps {
  src?: string;
  alt?: string;
  className?: string;
  busyClassName?: string;
}

const ImageWithLoading: React.FC<ImageWithLoadingProps> = ({
  src,
  alt,
  className,
  busyClassName
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!src) {
      return;
    }

    setIsLoading(true);

    const img = new Image();

    const handleLoad = () => {
      setIsLoading(false);
    };

    const handleError = () => {
      setIsLoading(false);
    };

    img.addEventListener("load", handleLoad);
    img.addEventListener("error", handleError);
    img.src = src;

    // Check if the image is already cached
    if (img.complete) {
      handleLoad();
    }

    return () => {
      img.removeEventListener("load", handleLoad);
      img.removeEventListener("error", handleError);
    };
  }, [src]);

  return (
    <div className={clsx(styles.Image, className)}>
      {isLoading ? (
        <div className={clsx(styles.imgLoading, busyClassName)}></div>
      ) : (
        <img src={src} alt={alt} />
      )}
    </div>
  );
};

export default ImageWithLoading;
