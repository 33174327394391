import { useState } from "react";

import { useVideoEditor } from "contexts/VideoEditorContext";

import NavButtons from "./Buttons";
import NavContexts from "./Contexts";

import styles from "./style.module.scss";

type SideNavProps = {
  className?: string;
};

export default function SideNav(props: SideNavProps) {
  const [activeTab, setActiveTab] = useState<string | null>("script");

  const { isSideNavCollapsed, setIsSideNavCollapsed } = useVideoEditor();

  const handleTabChange = (tabName: string) => {
    setActiveTab(tabName);
    setIsSideNavCollapsed(false);
  };

  const handleToggleCollapse = () => {
    setActiveTab(null);
    setIsSideNavCollapsed(true);
  };

  return (
    <div className={styles.sideNavWrap}>
      <NavButtons
        active={activeTab}
        onChange={tabName => handleTabChange(tabName)}
      />

      <NavContexts
        active={activeTab}
        collapse={isSideNavCollapsed}
        onToggleCollapse={() => handleToggleCollapse()}
      />
    </div>
  );
}
