import clsx from "clsx";

import SvgIcon from "components/General/SvgIcon";
import GenereateInput from "components/Dashboard/GenerateInput";

import UserDropdown from "../UserDropdown";
import { AvailableCredits } from "../AvailableCredits";

import styles from "./style.module.scss";

export default function Navbar({
  toggleSideNav
}: {
  toggleSideNav: () => void;
}) {
  return (
    <nav className={styles.navbar}>
      <div className={clsx("container", styles.container)}>
        <div className={clsx(styles.toggleContainer)}>
          <button
            onClick={e => {
              e.stopPropagation();
              toggleSideNav();
            }}
            className={styles.toggleButton}
          >
            <div className={styles.logoWrapper}>
              <SvgIcon name="menu" className={styles.toggleRight} />
              <div className={styles.logo}>
                <div className={styles.logoContent}>
                  <SvgIcon name="logo-icon-blue" className={styles.logoIcon} />
                  <div className={styles.logoText}>Soutle</div>
                </div>
                <div className={styles.by}> by Aptiw</div>
              </div>
            </div>
          </button>
        </div>
        <GenereateInput />

        <AvailableCredits />

        <div className={styles.right}>
          <div className={styles.navIcons}>
            <button className={styles.iconButtons}>
              <SvgIcon
                name="help-circle"
                className={clsx(styles.buttonIcon, styles.helpIcon)}
              />
            </button>
            <button className={styles.iconButtons}>
              <div className={styles.unreadNotification}>
                Unread Notification
              </div>
              <SvgIcon name="bell" className={styles.buttonIcon} />
            </button>
          </div>
          <UserDropdown />
        </div>
      </div>
    </nav>
  );
}
