import clsx from "clsx";
import { NavLink, useLocation } from "react-router-dom";

import styles from "./style.module.scss";

import SvgIcon from "components/General/SvgIcon";
import { useEffect, useState } from "react";

const navigations = [
  {
    label: "Account Settings",
    description: "Personal Information and Email",
    route: "/settings",
    icon: "user-filled",
    isEnd: true,
  },
  {
    label: "Security",
    description: "Password and account security",
    route: "/settings/security",
    icon: "security",
    isEnd: false,
  },
  {
    label: "Billing",
    description: "Subscription plans and billing",
    route: "/settings/billing",
    icon: "credit-card",
    isEnd: false,
  },
  {
    label: "Notifications",
    description: "Account notification preferences",
    route: "/settings/notifications",
    icon: "notification-filled",
    isEnd: false,
  },
];

export default function SettingsNav() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeNav, setActiveNav] = useState<null | (typeof navigations)[0]>(
    null
  );
  const location = useLocation();

  useEffect(() => {
    const currentNav =
      navigations.find((nav) => {
        if (nav.route === "/settings/billing") {
          return location.pathname.startsWith(nav.route);
        }
        return location.pathname === nav.route;
      }) || null;
    setActiveNav(currentNav);
  }, [location.pathname]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleNavItemClick = () => {
    if (window.innerWidth <= 768) {
      setIsDropdownOpen(false);
    }
  };
  return (
    <div className={styles.settingsNavContainer}>
      <button className={styles.toggleButton} onClick={toggleDropdown}>
        {activeNav && (
          <span className={styles.activeNavLabel}>{activeNav.label}</span>
        )}
        <SvgIcon name="dropdown" />
      </button>
      <nav
        className={clsx(styles.settingsNav, { [styles.open]: isDropdownOpen })}
      >
        <ul className={styles.navList}>
          {navigations.map((nav) => (
            <li key={nav.route}>
              <NavLink
                to={nav.route}
                className={({ isActive }) =>
                  clsx(styles.navLink, {
                    [styles.activeLink]: isActive,
                  })
                }
                end={nav.isEnd}
                onClick={handleNavItemClick}
              >
                {({ isActive }) => (
                  <>
                    <div
                      className={clsx(styles.iconWrap, {
                        [styles.iconWrapActive]: isActive,
                      })}
                    >
                      <SvgIcon name={nav.icon} className={styles.navIcon} />
                    </div>
                    <div className={styles.content}>
                      <div
                        className={clsx(styles.navlabel, {
                          [styles.labelActive]: isActive,
                        })}
                      >
                        {nav.label}
                      </div>
                      <div className={styles.description}>
                        {nav.description}
                      </div>
                    </div>
                    <div
                      className={clsx(styles.chevronWrap, {
                        [styles.chevronActive]: isActive,
                      })}
                    >
                      <SvgIcon name="chevron-right" />
                    </div>
                  </>
                )}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}
