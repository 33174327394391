import clsx from "clsx";
import SvgIcon from "../SvgIcon";

import "./style.scss";
import { MouseEvent } from "react";

interface VideoSize {
  id: string;
  name: string;
  description: string;
  disabled?: boolean;
  icon: string;
}

const sizes: VideoSize[] = [
  {
    id: "1080x1920",
    name: "Portrait (1080x1920)",
    description:
      "Ideal size for short form videos watched on mobile devices. Perfect for Tiktok videos, Instagram Reels, Youtube Shots, etc.",
    icon: "portrait"
  },
  {
    id: "1920x1080",
    name: "Landscape (1920x1080)",
    description: `<strong class="text-secondary">Coming Soon</strong> - Ideal size for long form videos watched on bigger screens. Perfect for Youtube videos, etc.`,
    disabled: true,
    icon: "landscape"
  }
];

interface SelectVideoSizeDropdownProps {
  className?: string;
  value: string;
  onChange?: (sizeId: string) => void;
}

const SelectVideoSizeDropdown = ({
  value = "",
  onChange,
  className
}: SelectVideoSizeDropdownProps) => {
  const handleOnSelectVideoSize = (size: VideoSize, e: MouseEvent) => {
    e.preventDefault();

    if (size.disabled) {
      return e.stopPropagation();
    }

    const sizeId = size.id;

    if (sizeId && sizeId !== value && typeof onChange === "function") {
      onChange(sizeId);
    }
  };

  let selectedVoice = sizes.find(v => v.id === value);

  if (!selectedVoice) {
    selectedVoice = {
      id: "select",
      name: "Select",
      description: "",
      disabled: false,
      icon: ""
    };
  }

  return (
    <div className={clsx("dropdown size-select-dropdown", className)}>
      <button
        className="btn btn-light btn-sm dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-offset="0,-30"
      >
        <SvgIcon name="portrait" className="resize-icon" />
        Resize
        <SvgIcon name="chevron-down" className="dropdown-chevron" />
      </button>
      <ul className="dropdown-menu">
        {sizes.map(size => (
          <li
            key={size.id}
            className={clsx({
              active: value === size.id,
              disabled: size.disabled
            })}
          >
            <div
              role="button"
              className="dropdown-item"
              onClick={e => handleOnSelectVideoSize(size, e)}
            >
              <div className="size-icon-wrap">
                <SvgIcon name={size.icon} />
              </div>
              <div className="">
                <div className="size-name">{size.name}</div>
                <div
                  className="size-desc-wrap"
                  dangerouslySetInnerHTML={{ __html: size.description }}
                ></div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectVideoSizeDropdown;
