import { z } from "zod";
import styles from "./style.module.scss";
import useBackend from "hooks/useBackend";
import { FormEvent, useState } from "react";
import { ValidationErrors } from "support/types";
import SubmitButton from "components/General/SubmitButton";
import Spinner from "components/General/Spinner";
import alertController from "contexts/AlertContext/controller";

export default function SecuritySettings() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPristine, setIsPristine] = useState<boolean>(true);
  const [errors, setErrors] = useState<ValidationErrors>({});

  const { put } = useBackend();

  const validateForm = (e: FormEvent<HTMLFormElement>) => {
    const data = new FormData(e.currentTarget);
    const schema = z
      .object({
        oldPassword: z
          .string()
          .nonempty({ message: "Old password is required" })
          .min(8, { message: "Current password is incorrect" }),
        newPassword: z
          .string()
          .min(8, { message: "New password must be at least 8 characters" }),
        passwordConfirmation: z.string(),
      })
      .refine((data) => data.newPassword === data.passwordConfirmation, {
        message: "Passwords don't match",
        path: ["passwordConfirmation"],
      });
    return schema.safeParse(Object.fromEntries(data));
  };

  const handleFormChange = async (e: FormEvent<HTMLFormElement>) => {
    if (isPristine) return;

    const zResult = validateForm(e);
    if (!zResult.success) {
      setErrors(zResult.error.flatten().fieldErrors);
    } else {
      setErrors({});
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPristine(false);

    const form = e.currentTarget;

    const zResult = validateForm(e);
    if (!zResult.success) {
      setErrors(zResult.error.flatten().fieldErrors);
      return;
    }

    setIsLoading(true);
    try {
      const response = await put("/users/change-password", {
        body: zResult.data
      });
      setIsLoading(false);

      if (response.ok) {
        alertController.open({
          icon: "success",
          message: "Password changed successfully!",
        });

        form.reset();
        setIsPristine(true);
        setErrors({});
      } else {
        const errorData = await response.json();
        const serverErrorMessage =
          errorData?.message || `Request failed with status ${response.status}`;
        throw new Error(serverErrorMessage);
      }
    } catch (error: any) {
      setIsLoading(false);
      alertController.open({
        icon: "error",
        message:
          error.message || "Failed to change password. Please try again.",
      });
      console.error(error);
    }
  };

  return (
    <div className={styles.securitySettings}>
      <h3>Security Settings</h3>
      <p>Manage your account security settings.</p>

      <form onSubmit={handleSubmit} onChange={handleFormChange}>
        <h4 className="mt-5 mb-4 text-primary">Change Password</h4>
        <div className="row">
          <div className="col">
            <div className="mb-3">
              <label htmlFor="oldPassword" className="form-label">
                Current Password
              </label>
              <input
                type="password"
                name="oldPassword"
                className={`form-control ${
                  errors.oldPassword ? "is-invalid" : ""
                }`}
                id="oldPassword"
              />
              {errors.oldPassword && (
                <div className="invalid-feedback">{errors.oldPassword[0]}</div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="newPassword" className="form-label">
                New Password
              </label>
              <input
                type="password"
                name="newPassword"
                className={`form-control ${
                  errors.newPassword ? "is-invalid" : ""
                }`}
                id="newPassword"
              />
              {errors.newPassword && (
                <div className="invalid-feedback">{errors.newPassword[0]}</div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="passwordConfirmation" className="form-label">
                Confirm Password
              </label>
              <input
                type="password"
                name="passwordConfirmation"
                className={`form-control ${
                  errors.passwordConfirmation ? "is-invalid" : ""
                }`}
                id="passwordConfirmation"
              />
              {errors.passwordConfirmation && (
                <div className="invalid-feedback">
                  {errors.passwordConfirmation[0]}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="text-end">
          <SubmitButton
            className="btn btn-primary text-white px-5 rounded-pill"
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner size="sm" color="light" />
            ) : (
              "Change Password"
            )}
          </SubmitButton>
        </div>
      </form>
    </div>
  );
}
