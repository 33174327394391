import Scrollbar from "components/General/Scrollbar";
import ContextHeader from "../../Reusables/ContextHeader";

import styles from "./style.module.scss";

export default function SettingsContext() {
  return (
    <div className={styles.navTablesContext}>
      <ContextHeader>
        <h4 className={styles.projectSettings}>Settings</h4>
      </ContextHeader>

      <Scrollbar className={styles.settngsContextBody}>
        <div className={styles.settingsSectionWrap}>
          <div className={styles.sectionTitle}>Thumbnail</div>

          <div className={styles.inputGroupLabel}>Include in Frames</div>
          <div className={styles.switchInputRow}>
            <div className={styles.inputDesc}>
              Add thumbnail as the first few frames of the video.
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                defaultChecked
                disabled
              />
            </div>
          </div>

          <div className={styles.inputGroupLabel}>Add Metadata</div>
          <div className={styles.switchInputRow}>
            <div className={styles.inputDesc}>
              Attach thumbnail in the video metadata
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                defaultChecked
                disabled
              />
            </div>
          </div>
        </div>

        <div className={styles.settingsSectionWrap}>
          <div className={styles.sectionTitle}>Watermark</div>

          <div className={styles.switchInputRow}>
            <div className={styles.inputDesc}>
              A watermark is an identifying image, shape, or piece of text over
              the video.
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                defaultChecked
                disabled
              />
            </div>
          </div>
          <button
            className="btn btn-primary text-white w-100 py-1 mt-2"
            disabled
          >
            Set Watermark
          </button>
        </div>
      </Scrollbar>
    </div>
  );
}
