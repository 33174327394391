import clsx from "clsx";

import { useVideoEditor } from "contexts/VideoEditorContext";

import styles from "./style.module.scss";

type VideoCaptionProps = {
  style?: string;
};

function getSentenceUpToWord(
  word: string,
  sentence: string,
  maxWords: number = 5
): string {
  // Function to normalize text by removing punctuation and converting to lower case
  const normalize = (str: string) =>
    str
      .replace(/[^\w\s]|_/g, "")
      .replace(/\s+/g, " ")
      .toLowerCase();

  // Normalize the sentence and the word
  const normalizedSentence = normalize(sentence);
  const normalizedWord = normalize(word);

  // Split the normalized sentence into words
  const wordsArray = normalizedSentence.split(" ");

  // Find the index of the normalized word in the normalized sentence
  const wordIndex = wordsArray.indexOf(normalizedWord);

  if (wordIndex !== -1) {
    // Split the original sentence into words, keeping punctuation attached
    const originalWords = sentence.match(/(\S+)/g);

    if (originalWords) {
      // Map the normalized words to the original words
      let foundIndex = -1;
      for (let i = 0, j = 0; i < originalWords.length && j <= wordIndex; i++) {
        const originalWordNormalized = normalize(originalWords[i]);
        if (originalWordNormalized === wordsArray[j]) {
          if (j === wordIndex) {
            foundIndex = i;
            break;
          }
          j++;
        }
      }

      if (foundIndex !== -1) {
        // Get the words up to and including the found index
        let resultWords = originalWords.slice(0, foundIndex + 1);

        // If the result exceeds maxWords, truncate from the left
        if (resultWords.length > maxWords) {
          resultWords = resultWords.slice(resultWords.length - maxWords);
        }

        // Join the words back into a string
        return resultWords.join(" ");
      }
    }
  }

  // If the word is not found, return the original sentence
  return sentence;
}

export default function VideoCaption({ style = "default" }: VideoCaptionProps) {
  const { video, currentScene, currentWord } = useVideoEditor();

  let captionPreview = null;

  switch (video?.captionStyle) {
    case "WordForWord":
      captionPreview = (
        <div className={clsx(styles.wordForWord, { [style]: true })}>
          {currentWord}
        </div>
      );
      break;

    case "KeepUp":
      if (!currentScene?.script) {
        captionPreview = null;

        break;
      }

      const sentence = getSentenceUpToWord(currentWord, currentScene.script);
      const words = sentence.split(" ");
      const word = words.pop();
      const line = words.join(" ");

      captionPreview = (
        <div className={clsx(styles.keepUp, { [style]: true })}>
          <div className={styles.keepUpLine}>
            {line} <span className={styles.keepUpCurrent}>{word}</span>
          </div>
        </div>
      );
      break;
  }

  return captionPreview;
}
