import clsx from "clsx";
import SvgIcon from "components/General/SvgIcon";

import styles from "./style.module.scss";

interface ContextSearchInputProps {
  className?: string;
  placeholder?: string;
}

export default function ContextSearchInput(props: ContextSearchInputProps) {
  return (
    <div className={clsx(styles.searchInputWrap, props.className)}>
      <input
        type="text"
        placeholder={props.placeholder}
        className={clsx("form-control", styles.searchInput)}
      />
      <button className={styles.searchInputButton}>
        <SvgIcon name="search" />
      </button>
    </div>
  );
}
