import { ChangeEvent } from "react";
import { useVideoEditor } from "contexts/VideoEditorContext";

import styles from "./style.module.scss";
// import Image from "components/General/Image";

export default function ImageAI() {
  const { isVideoBusy, currentScene, updateScene, regenerateSceneImage } =
    useVideoEditor();

  const handlePromptChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (!currentScene || !currentScene.image) {
      return;
    }

    updateScene({
      ...currentScene,
      image: {
        ...currentScene.image,
        prompt: e.target.value
      }
    });
  };

  return (
    <>
      <div className={styles.promptWrap}>
        <div className={styles.promptInputWrap}>
          <label htmlFor="">Image prompt</label>
          <textarea
            className="form-control"
            value={currentScene?.image?.prompt}
            rows={2}
            onChange={handlePromptChange}
          />
        </div>
        <button
          className="btn btn-primary text-white btn-sm rounded px-4"
          onClick={regenerateSceneImage}
          disabled={isVideoBusy}
        >
          Generate Image
        </button>
      </div>

      {/* {currentScene?.image && (
        <div className={styles.currentImageWrap}>
          <Image src={currentScene?.image.url} />
        </div>
      )} */}
    </>
  );
}
