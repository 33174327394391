import clsx from "clsx";
import AudioSamplePlayer from "../AudioSamplePlayer";
import SvgIcon from "../SvgIcon";

import "./style.scss";
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import Scrollbar from "../Scrollbar";

const cdnHost = process.env.REACT_APP_CDN_HOST;

interface Voice {
  id: string;
  name: string;
  description?: string;
  tags?: string[];
  otherTags?: string[];
  sample?: string;
}

const voices: Voice[] = [
  {
    id: "felie",
    name: "Felie",
    description: "Warm, friendly, and engaging",
    tags: ["Young", "Male"],
    otherTags: ["man", "youth"],
    sample: "felie.wav"
  },
  {
    id: "aniey",
    name: "Aniey",
    description: "Lively, vibrant, and dynamic",
    tags: ["Young", "Female"],
    otherTags: ["woman", "lady", "girl"],
    sample: "aniey.wav"
  },
  {
    id: "belio",
    name: "Belio",
    description: "Neutral, professional, and clear",
    tags: ["Young", "Male"],
    otherTags: ["man", "youth"],
    sample: "belio.wav"
  },
  {
    id: "rutie",
    name: "Rutie",
    description: "Young, energetic, and engaging",
    tags: ["Young", "Female"],
    otherTags: ["woman", "lady", "girl"],
    sample: "rutie.wav"
  },
  {
    id: "pascio",
    name: "Pascio",
    description: "Energetic, expressive, and engaging",
    tags: ["Young", "Male"],
    otherTags: ["man", "boy"],
    sample: "pascio.wav"
  },
  {
    id: "chibbex",
    name: "Chibbex",
    description: "Older, mature, and experienced",
    tags: ["Old", "Male"],
    otherTags: ["man"],
    sample: "chibbex.wav"
  }
];

interface SelectVoiceDropdownProps {
  value: string;
  disabled?: boolean;
  onChange?: (voiceId: string) => void;
}

const SelectVoiceDropdown = ({
  value = "",
  disabled,
  onChange
}: SelectVoiceDropdownProps) => {
  const [keyword, setKeyword] = useState("");
  const dropdownRef = useRef<HTMLButtonElement | null>(null);
  const keywordInputRef = useRef<HTMLInputElement | null>(null);

  const handleOnSelectVoice = (voiceId: string, e: MouseEvent) => {
    e.preventDefault();

    if (voiceId && voiceId !== value && typeof onChange === "function") {
      onChange(voiceId);
    }
  };

  let selectedVoice = voices.find(v => v.id === value);

  if (!selectedVoice) {
    selectedVoice = {
      id: "select",
      name: "Select"
    };
  }

  const handleKeywordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  const filteredVoices = voices.filter(v => {
    if (keyword) {
      const word = keyword.toLocaleLowerCase();

      return (
        v.name.toLocaleLowerCase().includes(word) ||
        v.description?.toLocaleLowerCase().includes(word) ||
        v.tags?.some(t => t.toLocaleLowerCase().includes(word)) ||
        v.otherTags?.some(t => t.toLocaleLowerCase().includes(word))
      );
    }

    return voices;
  });

  useEffect(() => {
    const handleOnDropdownShow = () => {
      if (keywordInputRef.current) {
        keywordInputRef.current.focus();
      }
    };

    const dropdownButton = dropdownRef.current;
    if (dropdownButton) {
      dropdownButton.addEventListener(
        "shown.bs.dropdown",
        handleOnDropdownShow
      );
    }

    return () => {
      dropdownButton?.removeEventListener(
        "shown.bs.dropdown",
        handleOnDropdownShow
      );
    };
  }, []);

  return (
    <div className="dropdown voice-select-dropdown">
      <button
        ref={dropdownRef}
        className="btn btn-light btn-sm dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-offset="0,-30"
        disabled={disabled}
      >
        <SvgIcon name="voice-circle" className="speak-icon" />
        {/* <SvgIcon name="user-speak" className="speak-icon" /> */}
        {selectedVoice.name}
        <SvgIcon name="chevron-down" className="dropdown-chevron" />
      </button>
      <div className="dropdown-menu">
        <div className="input-wrap">
          <input
            ref={keywordInputRef}
            type="text"
            className="search-input"
            placeholder="Search voices..."
            value={keyword}
            onChange={handleKeywordChange}
          />
        </div>
        <Scrollbar className="voices-scroll">
          <ul className="menu-items">
            {filteredVoices.length < 1 && (
              <div className="no-voices">No voices found</div>
            )}
            {filteredVoices.map(voice => (
              <li
                key={voice.id}
                className={clsx({ active: value === voice.id })}
              >
                <div
                  role="button"
                  className="dropdown-item"
                  onClick={e => handleOnSelectVoice(voice.id, e)}
                >
                  <div className="voice-name">{voice.name}</div>
                  {/* <div className="voice-tags-wrap">
                    {voice.tags?.map(t => (
                      <span key={t} className="voice-tag">
                        {t}
                      </span>
                    ))}
                  </div> */}
                  <div className="voice-desc-wrap">{voice.description}</div>
                  <div className="voice-sample-wrap">
                    <AudioSamplePlayer
                      className="voice-sammple-player"
                      src={`${cdnHost}/assets/voices/${voice.sample}`}
                      variant={value === voice.id ? "white" : "gray"}
                      playButtonLabel="Sample"
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </Scrollbar>
      </div>
    </div>
  );
};

export default SelectVoiceDropdown;
