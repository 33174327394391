import { z } from "zod";
import clsx from "clsx";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { ValidationErrors } from "support/types";
import toast from "support/toast";

import useBackend from "hooks/useBackend";

import styles from "./style.module.scss";
import GenerateInputDropdown from "./DropdownMenu";
import { Modal } from "components/General/Modal";

type GenerateEbookInputProps = {};

export default function GenerateEbookInput(props: GenerateEbookInputProps) {
  const navigate = useNavigate();
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPristine, setIsPristine] = useState<boolean>(true);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isMdScreenOrBelow = useMediaQuery({ query: "(max-width: 768px)" });

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const { post } = useBackend();

  const formRef = useRef<HTMLFormElement>(null);

  const validateForm = (e: FormEvent<HTMLFormElement>) => {
    const data = new FormData(e.currentTarget);

    const schema = z.object({
      title: z.string()
    });

    return schema.safeParse(Object.fromEntries(data));
  };

  const handleFormChange = async (e: FormEvent<HTMLFormElement>) => {
    if (isPristine) {
      return;
    }

    const zResult = validateForm(e);

    if (!zResult.success) {
      setErrors(zResult.error.flatten().fieldErrors);
    } else {
      setErrors({});
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsPristine(false);

    const zResult = validateForm(e);

    if (!zResult.success) {
      setErrors(zResult.error.flatten().fieldErrors);
    } else {
      setIsLoading(true);

      try {
        const res = await post("/videos/magicgen", {
          body: zResult.data
        });

        const jsonResonponse = await res.json();

        if (!res.ok) {
          if (jsonResonponse.type === "validation") {
            setErrors(jsonResonponse.errors);
          } else if (jsonResonponse.message) {
            toast.error(jsonResonponse.message);
          } else {
            // This will activate the closest `error.js` Error Boundary
            throw new Error("Failed to process data");
          }

          setIsLoading(false);
        } else if (jsonResonponse.status === 200) {
          const video = jsonResonponse.data.video;

          navigate(`/editor/${video.id}`);

          toast.success(
            "Your video has been successfully added to the render queue."
          );
          formRef.current?.reset();

          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        // Capture the error message to display to the user
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (errors) {
      for (const key in errors) {
        if (Object.prototype.hasOwnProperty.call(errors, key)) {
          const errs = errors[key];

          toast.error(errs[0]);
        }
      }
    }
  }, [errors]);

  return (
    <>
      {isMdScreenOrBelow ? (
        // Show the button on medium and smaller screens
        <button className={styles.modalTriggerButton} onClick={openModal}>
          Generate
        </button>
      ) : (
        <form
          ref={formRef}
          className={clsx(styles.generateInputWrap, {
            [styles.focused]: isFocused
          })}
          onSubmit={e => handleSubmit(e)}
          onChange={e => handleFormChange(e)}
        >
          {/* <label htmlFor="generateBook" className={styles.generateInputLabel}>
        Video title:
      </label> */}
          <input
            id="generateBook"
            type="text"
            name="title"
            placeholder="Enter Video Title"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            aria-label="Video title"
          />

          <GenerateInputDropdown loading={isLoading} />
        </form>
      )}

      {/* Use your existing modal for the form on mobile screens */}
      <Modal
        show={isModalOpen}
        onClose={closeModal}
        headerContent="Generate Video Title"
        size="md"
      >
        <form onSubmit={handleSubmit}>
          <input
            id="generateModalInput"
            type="text"
            name="title"
            placeholder="Enter Video Title"
            aria-label="Video title"
            className={styles.modalInput}
          />
          <button type="submit" className={styles.modalSubmitButton}>
            Generate
          </button>
        </form>
      </Modal>
    </>
  );
}
