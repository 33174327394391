import clsx from "clsx";
import SvgIcon from "components/General/SvgIcon";

import LoadingOrbs from "components/General/LoadingOrbs";
import Image from "components/General/Image";

import { useVideoEditor } from "contexts/VideoEditorContext";
import { useEffect, useState } from "react";
import CaptionPreview from "../CaptionPreview";

import { VideoScene } from "support/types/videos";

import styles from "./style.module.scss";

export default function ScenePreview() {
  const {
    video,
    isVideoBusy,
    isVideoLoading,
    isScenesLoading,
    someScenesPending,
    currentScene: scene,
    regenerateSceneImage
  } = useVideoEditor();
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      if (!target.closest(`.${styles.sceneRenderContainer}`)) {
        setIsSelected(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIsSelected(false);
  }, [scene]);

  const handleOnImageSelect = () => {
    setIsSelected(true);
  };

  const handleRenerateSceneImage = () => {
    setIsSelected(false);

    regenerateSceneImage();
  };

  const getThumbnail = (scene?: VideoScene) => {
    const allScenesReady = !someScenesPending;
    if (allScenesReady) {
      switch (video?.status) {
        case "queueing":
          return (
            <div className={styles.sceneCoverPhotoWrap}>
              <LoadingOrbs
                text="Queueing"
                variant="3"
                className={styles.sceneCoverPhoto}
              />
            </div>
          );
        case "generating":
          return (
            <div className={styles.sceneCoverPhotoWrap}>
              <LoadingOrbs
                text="Generating"
                variant="2"
                className={styles.sceneCoverPhoto}
              />
            </div>
          );
        case "rendering":
          return (
            <div className={styles.sceneCoverPhotoWrap}>
              <LoadingOrbs
                text="Rendering"
                className={styles.sceneCoverPhoto}
              />
            </div>
          );
      }
    }

    switch (scene?.status) {
      case "queueing":
        return (
          <div className={styles.sceneCoverPhotoWrap}>
            <LoadingOrbs
              text="Queueing"
              variant="3"
              className={styles.sceneCoverPhoto}
            />
          </div>
        );
      case "generating":
        return (
          <div className={styles.sceneCoverPhotoWrap}>
            <LoadingOrbs
              text="Generating"
              variant="2"
              className={styles.sceneCoverPhoto}
            />
          </div>
        );
    }

    if (scene?.image) {
      return (
        <div className={styles.sceneRenderWrap} onClick={handleOnImageSelect}>
          <Image src={scene?.image.mdThumbUrl} />
          <CaptionPreview />
        </div>
      );
    }

    return (
      <div className={styles.sceneCoverPhotoWrap}>
        <div className={styles.sceneNoThumb}>
          <SvgIcon name="video-file" className={styles.noThumbIcon} />
        </div>
      </div>
    );
  };

  const isLoading = isVideoLoading || isScenesLoading;

  return (
    <div
      className={clsx(styles.sceneRenderContainer, {
        [styles.sceneRenderLoading]: isLoading,
        [styles.selected]: isSelected
      })}
    >
      {isLoading ? (
        <div className={clsx(styles.sceneCoverPhotoWrap)}>
          <div className={styles.sceneLoading}>
            <SvgIcon name="video-file" className={styles.loadingIcon} />
          </div>
        </div>
      ) : (
        <>
          {isSelected && (
            <div className={styles.sceneRenderMenuContainer}>
              <div className={styles.sceneRenderMenu}>
                <button
                  className={clsx("btn", styles.menuButton)}
                  disabled={isVideoBusy}
                  onClick={handleRenerateSceneImage}
                >
                  <SvgIcon name="arrow-repeat" /> Re-generate Image
                </button>
                {/* <button className={clsx("btn", styles.menuButton)}>
                  <SvgIcon name="upload" /> Replace
                </button> */}
              </div>
            </div>
          )}

          {getThumbnail(scene)}
        </>
      )}
    </div>
  );
}
