import clsx from "clsx";
import styles from "./style.module.scss";
import { Link, Outlet } from "react-router-dom";
import SvgIcon from "components/General/SvgIcon";

export default function AuthLayout() {
  return (
    <main className={styles.main}>
      <section className={styles.loginSection}>
        <div className={clsx("container", styles.container)}>
          <Link to="/" className={styles.logo}>
            <div className="invisible">Soutle</div>
            <div className={styles.by}> by Aptiw</div>
          </Link>
          <div className={styles.loginRow}>
            <div className={styles.leftContent}>
              <Outlet />
            </div>
            <div className={styles.rightCol}>
              <div className={styles.rightContent}>
                <div className={styles.marketting}>
                  <h1 className={styles.markettingTitle}>
                    Create engaging videos in just one button Click
                  </h1>
                  <p className={styles.markettingDesc}>
                    Soutle creates, schedules, and posts engaging videos for
                    you, on auto-pilot. Each video is unique and customized to
                    your topic.
                  </p>
                </div>
                <div className={styles.testimonialBox}>
                  <p className={styles.testimonialText}>
                    “From start to finish , this platform exceeded my
                    expectations. I&apos;ve found my go-to for all future video
                    plans!”
                  </p>
                  <div className={styles.testimonialFooter}>
                    <div className={styles.testimonialName}>Jim S.</div>
                    <div className={styles.testimonailRight}>
                      <div className={styles.testimonialControls}>
                        <button className={styles.testimonailBtn}>
                          <SvgIcon name="chevron-left" />
                        </button>
                        <button className={styles.testimonailBtn}>
                          <SvgIcon name="chevron-right" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}