import clsx from "clsx";
import SvgIcon from "../SvgIcon";

import "./style.scss";
import { useEffect, useRef } from "react";
import Scrollbar from "../Scrollbar";
import { Video } from "support/types/videos";

interface ShareVideoDropdownProps {
  video: Video;
  disabled?: boolean;
  onChange?: (voiceId: string) => void;
}

const ShareVideoDropdown = ({ video, disabled }: ShareVideoDropdownProps) => {
  const dropdownRef = useRef<HTMLButtonElement | null>(null);
  const keywordInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const handleOnDropdownShow = () => {
      if (keywordInputRef.current) {
        keywordInputRef.current.focus();
      }
    };

    const dropdownButton = dropdownRef.current;
    if (dropdownButton) {
      dropdownButton.addEventListener(
        "shown.bs.dropdown",
        handleOnDropdownShow
      );
    }

    return () => {
      dropdownButton?.removeEventListener(
        "shown.bs.dropdown",
        handleOnDropdownShow
      );
    };
  }, []);

  return (
    <div className="dropdown share-video-dropdown">
      <button
        ref={dropdownRef}
        className={clsx("dropdown-toggle btn bg-white px-4")}
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-offset="0,15"
        disabled={disabled}
      >
        <SvgIcon name="share" className="play-icon" />
        Share
      </button>
      <div className="dropdown-menu">
        <div className="share-dropdown-header">Share this video</div>

        <Scrollbar className="share-dropdown-scroll">
          <div className="share-dropdown-content">
            <a
              href={video?.videoUrl}
              className="btn btn-primary text-white w-100"
              target="_blank"
              rel="noreferrer"
              download
            >
              Download
            </a>

            <div className="socials-wrap">
              <div className="socials-heading">Social</div>

              <div className="socials-items">
                <div className="social-item">
                  <button className="btn btn-social" disabled>
                    <SvgIcon name="facebook-colored" />
                  </button>

                  <div className="social-name">Facebook</div>
                </div>
                <div className="social-item">
                  <button className="btn btn-social" disabled>
                    <SvgIcon name="instagram-colored" />
                  </button>

                  <div className="social-name">Instagram</div>
                </div>
                <div className="social-item">
                  <button className="btn btn-social" disabled>
                    <SvgIcon name="youtube-colored" />
                  </button>

                  <div className="social-name">Youtube</div>
                </div>
                <div className="social-item">
                  <button className="btn btn-social" disabled>
                    <SvgIcon name="tiktok-colored" />
                  </button>

                  <div className="social-name">Tiktok</div>
                </div>
              </div>
            </div>
          </div>
        </Scrollbar>
      </div>
    </div>
  );
};

export default ShareVideoDropdown;
