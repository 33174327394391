import {
  // BubbleMenu,
  // FloatingMenu,
  EditorContent,
  useEditor
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import CharacterCount from "@tiptap/extension-character-count";

import "./style.scss";
import Scrollbar from "components/General/Scrollbar";
import clsx from "clsx";
import { useCallback } from "react";

const generateLodingTexts = (
  n: number
): Array<{ number: number; width: number }> => {
  const getRandomWidth = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const array = [];
  for (let i = 1; i <= n; i++) {
    array.push({ number: i, width: getRandomWidth(90, 100) });
  }
  return array;
};

type ScriptEditorProps = {
  className?: string;
  content?: string;
  maxLength?: number;
  isBusy?: boolean;
  showLimit?: boolean;
  isFixedHeight?: boolean;
  height?: number;
  onChange?: (value: string) => void;
  onCurrentWordChange?: (value: string) => void;
};

function getCurrentWord(text: string, cursorPosition: number): string {
  if (text.trim() === "") {
    return ""; // Return empty string for empty editor
  }

  const words = text.trim().split(/\s+/);

  if (cursorPosition === 0) {
    return words[0]; // Return the first word if cursor is at the beginning
  }

  if (cursorPosition >= text.length) {
    return words[words.length - 1]; // Return the last word if cursor is at the end
  }

  const beforeCursor = text.slice(0, cursorPosition);
  const afterCursor = text.slice(cursorPosition);

  const wordBefore = beforeCursor.split(/\s+/).pop() || "";
  const wordAfter = afterCursor.split(/\s+/)[0] || "";

  const currentWord = wordBefore + wordAfter;

  // If cursor is between words, return the word before the cursor
  return currentWord.trim() === ""
    ? beforeCursor.split(/\s+/).pop() || words[0]
    : currentWord;
}

const ScriptEditor = ({
  className,
  content = "",
  maxLength = 100,
  isBusy = false,
  showLimit = false,
  isFixedHeight,
  height = 58,
  onCurrentWordChange,
  onChange
}: ScriptEditorProps) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      CharacterCount.configure({
        limit: maxLength
      })
    ],
    content,

    onUpdate({ editor }) {
      updateCurrentWord(editor);

      // The content has changed.
      if (typeof onChange === "function") {
        onChange(editor.getText().trim());
      }
    },

    onSelectionUpdate({ editor }) {
      updateCurrentWord(editor);
    }
  });

  const updateCurrentWord = useCallback(
    (editor: any) => {
      const { from } = editor.state.selection;
      const currentPosition = from;
      const text = editor.state.doc.textBetween(
        0,
        editor.state.doc.content.size,
        "\n"
      );

      const word = getCurrentWord(text, currentPosition);

      if (typeof onCurrentWordChange === "function") {
        onCurrentWordChange(word);
      }
    },
    [onCurrentWordChange]
  );

  const count = editor?.storage.characterCount.characters();

  return (
    <>
      {isBusy ? (
        <div className="editorLoading">
          <div className="editorLoadingParagraph">
            {generateLodingTexts(5).map(n => (
              <div
                key={n.number}
                className="editorLoadingText"
                style={{ width: `${n.width}%` }}
              ></div>
            ))}
          </div>
          <div className="editorLoadingParagraph">
            {generateLodingTexts(5).map(n => (
              <div
                key={n.number}
                className="editorLoadingText"
                style={{ width: `${n.width}%` }}
              ></div>
            ))}
          </div>
        </div>
      ) : (
        <div
          className={clsx("script-editor-wrap", className, {
            focused: editor && editor.isFocused
          })}
        >
          {/* {editor && (
            <>
              <BubbleMenu
                editor={editor}
                tippyOptions={{ duration: 100, maxWidth: "none", arrow: true }}
              >
                <div className="bubble-menu">
                  <button>Rephrase</button>
                  <button>Shorten</button>
                  <button>Expand</button>
                  <button>Simplify</button>
                  <button>Fix Spelling</button>
                </div>
              </BubbleMenu>
              <FloatingMenu editor={editor} tippyOptions={{ duration: 100 }}>
                <div className="floating-menu">
                  <button>Continue</button>
                  <button>Rewrite</button>
                </div>
              </FloatingMenu>
            </>
          )} */}
          {isFixedHeight ? (
            <Scrollbar
              className="editor-scroll"
              style={{ height: `${height}px` }}
            >
              <EditorContent editor={editor} />
            </Scrollbar>
          ) : (
            <EditorContent editor={editor} />
          )}

          {showLimit && (
            <div className="header-toolbar">
              <div className="toolbar-col"></div>
              <div className="toolbar-col">
                <div className="toolbar-char-limit">
                  {count?.toLocaleString()} / {maxLength?.toLocaleString()}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ScriptEditor;
