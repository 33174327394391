import clsx from "clsx";

import styles from "./style.module.scss";

import { ReactComponent as VideoVector } from "assets/vectors/video.svg";
import { ReactComponent as SeriesVector } from "assets/vectors/series.svg";
import { ReactComponent as ChannelsVector } from "assets/vectors/channels.svg";

import { useEffect, useState } from "react";
import { useAuth } from "contexts/AuthContext";
import useBackend from "hooks/useBackend";

export default function WelcomeBox() {
  const { user } = useAuth();
  const [totalVideosCount, setTotalVideosCount] = useState(0);
  const { get } = useBackend();

  useEffect(() => {
    handleGetUserStats();
  }, []);

  const handleGetUserStats = async () => {
    try {
      const response = await get("/users/user-stats");
      if (response.ok) {
        const data = await response.json();
        setTotalVideosCount(data.data.totalVideosCount || 0);
      }
    } catch (error) {}
  };

  return (
    <div className={styles.welcomeBox}>
      <div className={styles.boxContent}>
        <h1 className={styles.title}>
          Welcome {user && `${user.firstName} ${user.lastName}`}
        </h1>
        <p className={styles.description}>
          Let's create something awesome today!
        </p>
      </div>
      <div className={styles.boxStats}>
        <div className={clsx(styles.statBox, styles.lightBlue)}>
          <div className={styles.statNumber}>{totalVideosCount}</div>
          <div className={styles.statLabel}>Videos</div>
          <VideoVector />
        </div>
        <div className={clsx(styles.statBox, styles.lightPurple)}>
          <div className={styles.statNumber}>0</div>
          <div className={styles.statLabel}>Series</div>
          <SeriesVector />
        </div>
        <div className={clsx(styles.statBox, styles.lightRed)}>
          <div className={styles.statNumber}>0</div>
          <div className={styles.statLabel}>Channels</div>
          <ChannelsVector />
        </div>
      </div>
    </div>
  );
}
