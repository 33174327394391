import SvgIcon from "components/General/SvgIcon";

import { VideoScene } from "support/types/videos";

import LoadingOrbs from "components/General/LoadingOrbs";
import Scrollbar from "components/General/Scrollbar";
import Image from "components/General/Image";

import styles from "./style.module.scss";
import clsx from "clsx";
import { useVideoEditor } from "contexts/VideoEditorContext";

export default function EditorTimeline() {
  const {
    isVideoLoading,
    scenes,
    currentScene,
    isScenesLoading,
    setCurrentSceneIndex,
    setCurrentWord,
    isVideoPlayable,
    playVideo,
    isSideNavCollapsed
  } = useVideoEditor();

  const generateScenesLoading = (n: number) => {
    return Array.from({ length: n }, (_, index) => (
      <div key={index + 1} className={styles.sceneItem}>
        <div className={styles.sceneLoadingItem}></div>
      </div>
    ));
  };

  const getImageThumbnail = (scene: VideoScene) => {
    if (scene.image?.thumbUrl) {
      return (
        <Image
          src={scene.image?.thumbUrl}
          alt={scene.prompt}
          busyClassName={styles.imgBusy}
        />
      );
    }

    switch (scene.status) {
      case "ready":
        return (
          <Image
            src={scene.image?.thumbUrl}
            alt={scene.prompt}
            busyClassName={styles.imgBusy}
          />
        );
      case "queueing":
        return (
          <div className={styles.sceneLoadingItem}>
            <LoadingOrbs
              variant="3"
              text=""
              className={styles.sceneGenerating}
            />
          </div>
        );
      case "generating":
        return (
          <div className={styles.sceneLoadingItem}>
            <LoadingOrbs
              variant="2"
              text=""
              className={styles.sceneGenerating}
            />
          </div>
        );
      case "pending":
        return <div className={styles.sceneLoadingItem}></div>;
      default:
        return (
          <div className={styles.sceneLoadingItem}>
            <SvgIcon name="question-mark" />
          </div>
        );
    }
  };

  const handleOnSceneClick = (scene: VideoScene, i: number) => {
    setCurrentSceneIndex(i);

    const words = scene.script?.trim().split(/\s+/);

    setCurrentWord(words?.length ? words[0] : "");
  };

  return (
    <div
      className={clsx(styles.timelineContainer, {
        [styles.sideNavOpen]: !isSideNavCollapsed
      })}
    >
      <span
        className={styles.playButtonWrap}
        data-bs-custom-class="white-tooltip"
        data-bs-toggle={!isVideoPlayable ? "tooltip" : null}
        data-bs-placement="top"
        data-bs-title='Changes detected in your video. Click the "Render" button to rerender the video.'
      >
        <button
          className={clsx("btn", styles.playButton)}
          disabled={isVideoLoading || isScenesLoading || !isVideoPlayable}
          onClick={playVideo}
        >
          <SvgIcon name="play" className={styles.playIcon} />
        </button>
      </span>

      <Scrollbar className={styles.scenesScroll}>
        <div className={styles.scenesWidth}>
          {isScenesLoading || isVideoLoading
            ? generateScenesLoading(12)
            : scenes?.map((scene, i) => (
                <div
                  key={scene.id}
                  className={clsx(styles.sceneItem, {
                    [styles.activeScene]: scene.id === currentScene?.id
                  })}
                  onClick={() => handleOnSceneClick(scene, i)}
                >
                  {getImageThumbnail(scene)}
                </div>
              ))}
        </div>
      </Scrollbar>
    </div>
  );
}
